import React from 'react';
import './styles.scss';

class Working extends React.Component {
    render() {
        return (
            <div className='pictionaryWorkingWrap'>
                <div className='pictinaryContainer'>
                    <div className='pWorkingHeader'>
                        <h3>How does it work?</h3>
                    </div>
                    <div className='pWorkingCardWrap'>
                        <div className='pWorkingCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/trivia/pictionary-online/building-blocks.png?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/trivia/pictionary-online/building-blocks.png'}
                                alt={'enjoy'}
                            />
                            <h5>Add Trivia to Slack or Microsoft Teams</h5>
                            <p>
                                Click the ‘Add Trivia’ button on the top of this page or install it directly from the
                                Slack or Microsoft Teams App Directory.{' '}
                            </p>
                        </div>
                        <div className='pWorkingCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/trivia/pictionary-online/imagery.png?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/trivia/pictionary-online/imagery.png'}
                                alt={'give command'}
                            />
                            <h5>Simply type the command and you’re good to go</h5>
                            <p>For Slack - /trivia pictionary</p>
                            <p>For Teams - @Trivia pictionary</p>
                        </div>
                        <div className='pWorkingCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/trivia/pictionary-online/catch-up.png?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/trivia/pictionary-online/catch-up.png'}
                                alt={'catch up'}
                            />
                            <h5>Catch-up, and enjoy Pictionary together</h5>
                            <p>
                                No extra logins. No clunky apps. <br />
                                Simply join the link through your browser and get started instantly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Working;
