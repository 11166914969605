import React from 'react';
import './styles.scss';

class Engaging extends React.Component {
    render() {
        return (
            <div className='funLEngageWrap'>
                <div className='pictinaryContainer'>
                    <div className='funLEngageHeader'>
                        <h3>Fun, Lightweight, and Engaging</h3>
                        <p>That’s easier said than done. And that’s where Trivia’s Pictionary comes in. </p>
                    </div>

                    <div className='funLEngageParent'>
                        <div className='funLEngage'>
                            <div className='funLEngageSection'>
                                <h6>
                                    Without Pens,
                                    <br />
                                    Whiteboards, or Paperclips
                                </h6>
                                <p>
                                    Right from digital whiteboards, moderators to score calculators, we offer everything
                                    you need to play a perfect game of Pictionary without any hassle.{' '}
                                </p>
                            </div>
                            <div className='funLEngageSection'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/pictionary-online/pens.png?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/pictionary-online/pens.png'}
                                    alt={''}
                                />
                            </div>
                        </div>
                        <div className='funLEngage'>
                            <div className='funLEngageSection'>
                                <h6>
                                    Feels as Good as
                                    <br />
                                    Playing in Real-Time
                                </h6>
                                <p>
                                    More than the game, it’s bringing out the funny artistic side and the hilarious
                                    guesses people make. So we recreated the digital equivalent of the game to keep
                                    things lively and capture memorable moments for your team to relish playing
                                    together.{' '}
                                </p>
                            </div>
                            <div className='funLEngageSection'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/pictionary-online/feels.png?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/pictionary-online/feels.png'}
                                    alt={''}
                                />
                            </div>
                        </div>
                        <div className='funLEngage'>
                            <div className='funLEngageSection'>
                                <h6>
                                    Build Stronger
                                    <br />
                                    Bonds with your Team
                                </h6>
                                <p>
                                    Encourage participation and enliven your virtual happy hours. Boost camaraderie and
                                    fun amongst your team by playing pictionary.{' '}
                                </p>
                            </div>
                            <div className='funLEngageSection'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/pictionary-online/build.png?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/pictionary-online/build.png'}
                                    alt={''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Engaging;
