import React from 'react';
import Navbar from '../../../components/trivia/NavbarOld';
import Header from '../../../components/trivia/Pictionary/Header';
import GlobalClient from '../../../components/trivia/GlobalClient';
import Footer from '../../../components/trivia/Footer';
import { Helmet } from 'react-helmet';
import Working from '../../../components/trivia/Pictionary/Working';
import Engaging from '../../../components/trivia/Pictionary/Engaging';
import TriviaUsers from '../../../components/trivia/TriviaUsers';
import Testimonials from '../../../components/trivia/Testimonials';
import './styles.scss';

const Pictionary = () => {
    return (
        <>
            <Helmet>
                <title>Skribbl & Play Virtual Pictionary in Slack & Teams | Trivia</title>
                <meta
                    data-react-helmet='true'
                    name='description'
                    content='Skribbl a little, guess a few words and play online Pictionary with your teammates right inside Slack and Microsoft Teams with Trivia'
                />
                <meta property='og:locale' content='en_US' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='Skribbl & Play Virtual Pictionary in Slack & Teams | Trivia' />
                <meta
                    property='og:description'
                    content='Skribbl a little, guess a few words and play online Pictionary with your teammates right inside Slack and Microsoft Teams with Trivia'
                />
                <meta property='og:url' content='https://www.springworks.in/trivia/pictionary-online/' />
                <meta property='og:site_name' content='Springworks' />
                <meta property='og:image' content='https://assets-springworks.s3.amazonaws.com/TriviaPictionary.jpeg' />
                <meta
                    property='og:image:secure_url'
                    content='https://assets-springworks.s3.amazonaws.com/TriviaPictionary.jpeg'
                />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='600' />
                <meta name='twitter:card' content='summary_large_image' />
                <meta
                    name='twitter:description'
                    content='Skribbl a little, guess a few words and play online Pictionary with your teammates right inside Slack and Microsoft Teams with Trivia'
                />
                <meta name='twitter:title' content='Skribbl & Play Virtual Pictionary in Slack & Teams | Trivia' />
                <meta name='twitter:creator' content='@springroleinc' />
                <meta
                    name='twitter:image'
                    content='https://assets-springworks.s3.amazonaws.com/TriviaPictionary.jpeg'
                />
                <link rel='canonical' href='https://www.springworks.in/trivia/pictionary-online/' />
            </Helmet>
            <div className='about-us-wrap'>
                <Navbar />
                <Header />
                <GlobalClient />
                <Working />
                <Engaging />
                <Testimonials />
                <TriviaUsers content={'Start playing pictionary with \nyour team, virtually.'} />
                <Footer />
            </div>
        </>
    );
};

export default Pictionary;
