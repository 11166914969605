import React from 'react';
import CTAButtons from '../../CTAButtons';
import './styles.scss';

const slackAddUrl = process.env.GATSBY_TRIVIA_SLACK_ADD_URL;
const teamsAddUrl = process.env.GATSBY_TRIVIA_MS_TEAMS_URL;

class Header extends React.Component {
    render() {
        return (
            <div className='pictionaryHeaderWrap'>
                <div className='pictionaryHeaderLeft'>
                    <h1>‘Pictionary can’t be played virtually’, said no one</h1>
                    <p>
                        Do you miss playing Pictionary with your team? Well, Trivia’s Pictionary is a digital equivalent
                        for teams who breathe Slack and Microsoft Teams.{' '}
                    </p>
                    <CTAButtons
                        slackButtonID='TRIVIA-SLACK-INSTALL'
                        teamsButtonID='TRIVIA-TEAMS-INSTALL'
                        slackAddUrl={slackAddUrl}
                        teamsAddUrl={teamsAddUrl}
                    />
                </div>
                <div className='pictionaryHeaderRight'>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/pictionary-online/pictionary.gif?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/trivia/pictionary-online/pictionary.gif'}
                        alt={''}
                    />
                </div>
            </div>
        );
    }
}

export default Header;
